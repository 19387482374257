.availableSlotsContainer {
  display: flex;
  height: 132px;
  background-color: #f4f4f4;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  color: #383838;
  gap: 20px;
}

.navigationIcon {
  cursor: pointer;
}

.navigationRightIcon {
  cursor: pointer;
  margin-left: auto;
}

.slotsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  height: 100%;
}

.calendarIcon {
  width: 60px;
}

.availableSlotsText {
  font-size: 25px;
  min-width: 160px;
  text-align: center;
}

.slot {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #606060;
  cursor: pointer;
}

.slotTime {
  font-size: 18px;
  border: 1px solid #ffd100;
  border-radius: 20px;
  padding: 5px 20px;
}

.slotDivider {
  color: #ffd100;
  font-size: 25px;
}

.slots {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;
  gap: 13px;
}

@media (max-width: 768px) {
  .availableSlotsContainer {
    margin-right: -10px;
  }

  .availableSlotsText {
    display: none;
  }
}

@media (max-width: 300px) {
  .availableSlotsContainer {
    padding: 10px;
    gap: 10px;
  }

  .navigationIcon {
    width: 25px;
    height: 25px;
  }

  .navigationRightIcon {
    width: 25px;
    height: 25px;
  }

  .slotTime {
    font-size: 12px;
  }
  .slotsWrapper {
    gap: 10px;
  }
}
