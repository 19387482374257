.socials {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

@media (max-width: 768px) {
  .socials {
    flex-direction: column;
  }
}
