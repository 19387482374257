.careers{
    border: 1px solid;
    width: 30%;
    margin-left: 10px;
    margin-top: 100px;
    height: 200px;
    justify-content:space-between;
    
}
#JoinUs{
    margin-top: auto
}
.careerParent{
    display: flex;
}
.careerTitle{
    color: #FFD000;
    margin-bottom: 25px;
    margin-top: 25px;
    margin-left: 25px;
}

.arrowButton {
    color: #FFD000;
    border-color: #FFD000;
    background-color: white;
    border-radius: 40px;
    width: 100px;
    float:right;
    margin-right: 10px;
}

.joinButton {
    color: black;
    border-color: #FFD000;
    border-style: solid;
    border-radius: 40px;
    min-width: 100px;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 8px;
    font-family: 'MyriadVariableConcept-Roman', sans-serif;
}

.careerDescription{
    margin-left: 25px;
}

.Bee_img{
    float: right;
}

.JoinUs{
    display: flex;
    justify-content: space-between;
}