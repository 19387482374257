.availabilitySettingsContainer {
  display: flex;
  flex-direction: row;
}

.column {
  width: 48%;
}

.verticalDivider {
  height: auto;
}
