.calendarContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.calendarLayout {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;
}

.sidebar {
  width: 30px;
  background: #ffd100;
}

.calendarMain {
  width: 95%;
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dateCell {
  border-right: 1px solid #ccc;
  display: flex;
  justify-content: center;
}

.dateNumber {
  width: 181px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 35px;
}

.monthCell {
  border-right: 1px solid #ccc;
  display: flex;
  justify-content: center;
}

.monthName {
  width: 181px;
  margin-bottom: 58px;
  text-align: center;
  font-size: 25px;
}

.colorOtherMonth {
  color: #e4e4e4;
}

.colorWeekend {
  color: #acacac;
}

.colorSelectedDate {
  color: #ff6f00;
}

.colorAvailableDate {
  color: #ffd100;
}

.colorDefault {
  color: #5e5e5e;
}

@media (max-width: 768px) {
  .dateNumber {
    font-size: 25px;
  }
}
