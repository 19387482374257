.job {
  border-radius: 4px;
  border: 1px solid #ccd1d9;
  padding: 20px;
  margin-top: 30px;
}

.department {
  color: #fdbe57;
  background: #e6f0f6;
  display: block;
  float: left;
  padding: 4px 8px;
  margin-right: 12px;
  border-radius: 4px;
}

.apply-now {
  text-align: right;
  float: right;
}

.btn {
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 18px;
}

.btn-primary {
  color: #fff;
  background-color: #fdbe57 !important;
  border-color: #ccc;
}

.row {
  display: flex;
}

#recruitee-careers .rt-theme-light.rt-body {
  box-shadow: unset !important;
  border: none !important;
}

#recruitee-careers-details-container
  .rt-theme-light.rt-details__outer--popup
  .rt-details__backdrop {
  opacity: 20%;
}

#recruitee-careers-details-container
  .rt-theme-light.rt-details__outer--popup
  .rt-details__inner {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.rt-list__offer-item::before {
  content: url("../../images/offers-icon.png");
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

#recruitee-careers .rt-theme-light .rt-list__offer-item + .rt-list__offer-item {
  border-width: 3px !important;
  border-color: #cfcfcf !important;
  padding-top: 3em !important;
}

#recruitee-careers .rt-list__offer-title {
  font-family: MyriadVariableConcept-Roman, Cairo-Regular, sans-serif !important;
  font-weight: bold !important;
  font-size: 1.5em !important;
  text-decoration: none !important;
}

#recruitee-careers-details-container .rt-details__content--main ul {
  list-style-type: disc !important;
}
