/* Add the animation class to the SVG element */
.headerContent{
    margin-right: 126px;
}
.bee {
    position: absolute;
    margin-left: 200px;
    margin-top: 60px;
    cursor: pointer;
    transform: scale(1.2);
}
.bee:not(:hover) {
    animation: vibration 0.3s ease infinite;
    transform: scale(1.2);
}

/* Define the vibration animation */
@keyframes vibration {
    0% { transform: translate(0, 0) scale(1.2); }
    25% { transform: translate(-2px, -2px)  scale(1.2); }
    50% { transform: translate(2px, 2px)  scale(1.2); }
    75% { transform: translate(-2px, 2px)  scale(1.2); }
    100% { transform: translate(2px, -2px)  scale(1.2); }
}