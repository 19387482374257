h3.section-title {
  font-size: 35px;
  color: #2a333d;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 12px;
}

h4.section-subtitle {
  font-size: 16px;
  color: #8aa2ad;
  font-weight: 600;
  margin-bottom: 30px;
}

h3.section-title,
h4.section-subtitle {
  text-transform: uppercase;
  text-align: center;
}

.offers-container {
  width: 100%;
  padding-left: 3%;
}
