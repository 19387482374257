.dayContainer {
  margin-bottom: 20px;
}

.dayHeader {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.slotContainer {
  display: flex;
  margin-bottom: 8px;
  align-items: baseline;
}

.dayAvailability {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  margin-bottom: 0;
}
