.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdownContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.monthText {
  font-size: 40px;
  font-weight: 700;
  color: #4e4e4e;
  cursor: pointer;
}
.yearText {
  font-size: 40px;
  font-weight: 700;
  color: #e4e4e4;
  cursor: pointer;
}

.currentDateText {
  font-size: 40px;
  font-weight: 700;
  color: #ffd100;
}

.dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdownArrow {
  width: 15px;
  height: auto;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .monthText {
    font-size: 20px;
  }
  .yearText {
    font-size: 20px;
  }
  .currentDateText {
    font-size: 20px;
  }
}
