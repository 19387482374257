.ant-menu-sub{
    background-color: #0000006e !important;
    padding: 4px 0 !important;
    margin: 0;
}

.ant-menu-submenu {
  background-color: transparent !important;

    @media (max-width: 768px) {
        padding-bottom: 5px;
        &:after {
            display: none;
        }
    }
}

.ant-menu-item, .ant-menu-submenu-vertical {
    border: 1px solid #ffbc00;
    color: #fff !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    &:not(:last-child) {
        border-bottom: 0!important;
    }
    &:hover, &:active, &:focus {
        background: rgba(255, 188, 0, 0.12) !important;
    }
}

.ant-menu-overflow{
    @media (max-width: 768px) {
        max-width: 100px;
    }
}

.ant-menu-vertical{
    display: flex;
    flex-direction: column;
    min-width: 120px !important;
    font-size: 12px;
    margin-right: -10px !important;
}

.ant-menu-submenu-vertical .ant-menu-submenu-arrow{
 display: none;
}

.ant-menu-submenu-vertical .ant-menu-submenu-title {
    padding: 0 !important;
}
