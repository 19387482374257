.letschatecalendar .ant-picker-body th {
  padding-inline-end: 0px !important;
  padding-bottom: 0px !important;
}

.letschatecalendar .ant-picker-body > table > thead > tr th {
  font-weight: normal;

  border-right: 1px solid #ccc;
  font-size: 16px;
  text-align: center;
}

.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(1) {
  visibility: hidden;
  padding-inline-end: 0px;
}
.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(1):after {
  visibility: visible;
  content: "Sunday";
  display: block;
  padding-bottom: 20px;
}

.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(2) {
  visibility: hidden;
  padding-inline-end: 0px;
}
.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(2):after {
  visibility: visible;
  content: "Monday";
  display: block;
  padding-bottom: 20px;
}

.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(3) {
  visibility: hidden;
  padding-inline-end: 0px;
}
.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(3):after {
  visibility: visible;
  content: "Tuesday";
  display: block;
  padding-bottom: 20px;
}

.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(4) {
  visibility: hidden;
  padding-inline-end: 0px;
}
.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(4):after {
  visibility: visible;
  content: "Wednesday";
  display: block;
  padding-bottom: 20px;
}

.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(5) {
  visibility: hidden;
  padding-inline-end: 0px;
}
.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(5):after {
  visibility: visible;
  content: "Thursday";
  display: block;
  padding-bottom: 20px;
}

.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(6) {
  visibility: hidden;
  padding-inline-end: 0px;
}
.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(6):after {
  visibility: visible;
  content: "Friday";
  display: block;
  padding-bottom: 20px;
}

.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(7) {
  visibility: hidden;
  padding-inline-end: 0px;
  border-right: 0px;
}
.letschatecalendar .ant-picker-body > table > thead > tr th:nth-child(7):after {
  visibility: visible;
  content: "Saturday";
  display: block;
  padding-bottom: 20px;
}

.custom-calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-dropdown-link {
  cursor: pointer;
}

.down-arrow {
  display: inline-block;
  margin-left: 8px;
}

.slots {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
  overflow: auto;
}

@media (max-width: 768px) {
  .letschatecalendar .ant-picker-body th {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}
