@font-face {
  font-family: DIN-NEXT-ARABIC-LIGHT;
  src: url('./fonts/DIN-NEXT-ARABIC-LIGHT.otf')  format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Abdo-Master-SemiBold;
  src: url('./fonts/Abdo-Master-SemiBold.otf')  format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: abdomaster-medium;
  src: url('./fonts/abdomaster-medium.ttf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: MyriadVariableConcept-Roman;
  src: url('./fonts/MyriadVariableConcept-Roman.otf')  format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Co-Headline-W23-Arabic-Light-4;
  src: url('./fonts/Co-Headline-W23-Arabic-Light-4.ttf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Cairo-Regular;
  src: url('./fonts/Cairo-Regular.ttf')  format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Cairo-SemiBold;
  src: url('./fonts/Cairo-SemiBold.ttf')  format('opentype');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html , body {
  width: 100% !important;
  overflow-x: hidden !important;
}